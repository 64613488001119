<template>
  <div class="page-content w-100 ">
<div class="row">
  <div class="col-sm-12">
    <p class="text-center page-heading">Clinic Manager</p>
  </div>
</div>
<div class="left-space-desktop">
<div class="row" style="margin-left:auto;margin-right:auto;">
<div class="col-md-12 mx-auto">
<label>Mobile number</label>
<div class=" searchPatientText mt-2 mb-2">
<div style="margin-right: 5px;">
<input type="text" id="mobile" maxlength="10" v-model="mobile" @input="mobileNumberValidate" v-on:keyup.enter="isMobileValid ? listDoctor() : null" class="form-control" />
</div>
<div class=" searchBtn" id="patient_list">
<span>
<button type="button" @click="listDoctor()" :disabled="!isMobileValid" class="btn btn-blue-color text-white"
>Search</button>
</span>
<!-- </div> -->
<!-- <div class="col-4 col-lg-4 col-md-4 col-sm-4" > -->
<span>
<button type="button" @click="clearDoctor()" style="margin-left: 4px;" class="btn btn-color text-white"
>Clear</button>
</span>
</div>
</div>
</div>
</div>
<div class="col-sm-12 settingsStyle px-3" v-if="doctorPayload?.mobile">
<nav class="nav-doctorTab">
  <ul class="doctorTabs">
    <li class="tab"  v-for="tab in doctorTabs" :key="tab" :class="!tab.isVisble ? 'visible-none':''" @click="navigatePage(tab.slug)">
    <p v-if="tab.isVisble" :class="tab.isActive ? 'activedoctorTab' : ''" >{{ tab.name }}</p>
    </li>
  </ul>
 </nav>
 <div class="text-border"></div>
      <div class="tab-content" id="nav-tabContent">
        <div v-if="currentPage === 'about-me'">
          <about-me :docData ="doctorPayload"></about-me>
        </div>
        <div v-if="currentPage === 'my-practice'">
          <practice :docData ="doctorPayload"></practice>
        </div>
      </div>
    </div>
    <div v-if="norecord ">
    <h3 class="text-center noRecordText mt-3">No records found</h3>
    </div>
</div>
</div>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import AboutMe from "../doctor/settings/aboutMe.vue"
import Practice from "../doctor/settings/practice.vue"
import axios from "axios";
export default {
components: {
AboutMe,
Practice,
},
data() {
return {
mobile:'',
doctorPayload:null,
norecord:false,
doctorTabs:[
{name:'About Me', slug: 'about-me',isVisble:true,isActive:true},
{name:'Consultations - My places', slug: 'my-practice',isVisble:true,isActive:false},
],
currentPage: "about-me"
}
  },
created: function () {
},
computed:{

isMobileValid() {
  const mobilevalidation = /^[6-9]\d{9}$/;
  return mobilevalidation.test(this.mobile);
},
},
methods:{
async navigatePage(slug){
const message = await this.getAccess();
    if(message){
    this.$swal(message)
    return
    }
this.doctorTabs.map((tab)=>{
if(tab.slug == slug) {
      tab.isActive = true
      this.currentPage = slug
} else {
  tab.isActive = false
}
})
},
async getAccess() {
let restrictAccess = ""
const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
await axios
.get(`${BASE_API_URL}/hcps/${localStorage.getItem("id")}/one`)
.then((response) => {
  if (response?.data) {
    if (response.data?.status === null) {
      restrictAccess = "Please complete the About Me section of the Settings Page"
    } else if (response.data?.status?.status === "UnderReview") {
      Swal.fire({
      title: "Your request for access is under review. Please wait.",
      confirmButtonColor: "#7066e0",
      confirmButtonText: "OK",
      }).then((result) => {
    if (result.isConfirmed) {
    this.$router.push('/doctor/index')
      }
    });
    } else if (response.data?.status?.status === "Approved") {
      restrictAccess = ""
    } else if (response.data?.status?.status === "Rejected") {
      restrictAccess= "Please fix the review comments of your request for access"
    }
  }
});
return restrictAccess
},
mobileNumberValidate(){
  this.mobile = this.mobile.replace(/\D/g, '');
},
listDoctor(){
const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
let mobilePayload={
    mobile:this.mobile
    }
  axios
.post(`${BASE_API_URL}/hcps/get-sign`, mobilePayload)
.then((response) => {
  if(response.data?.status?.status === "Approved"){
    this.doctorPayload=response.data

  }else{
    this.norecord=true
  }
})

},
clearDoctor(){
this.mobile='',
this.doctorTabs.map((tab)=>{
if(tab.slug==='about-me') {
      tab.isActive = true
      this.currentPage = 'about-me'
} else {
  tab.isActive = false
}
})
this.doctorPayload=false,
this.norecord=false
}
}
};
</script>
<style>
.noRecordText {
color: red;
}
.left-space-desktop {
margin-left: 20px;
}
.searchBtn{
display: flex;
justify-content: flex-start;
}
.searchPatientText {
margin-top: 0rem;
display: flex;
justify-content: flex-start;
}
.visible-none{
display: none;
}
.col-sm-12.settingsStyle {
margin: 0px 0px 0px 10px;
background-color: #ffff;
border-radius: 10px;
padding-bottom: 15px;
box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%), 0 1px 2px 0 rgb(0 0 0 / 12%);
}
ul.doctorTabs{
list-style:none ;
cursor: pointer;
display: flex;
padding: 0;
margin-bottom: 5px;

}
li.tab p{
padding: 8px 12px;
margin-bottom: 0;
}
.activedoctorTab{
background-color: #34989f;
color: #fff;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
}

.nav-doctorTab{
padding-top: 15px;
}

.text-border {
background-color: #00979e;
height: 4px;
margin-bottom: 20px;
}

</style>